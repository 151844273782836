<template>
  <div class="payroll-deduction-scheme">

    <h1>Our Payroll Deduction Scheme</h1>

    <b-card-group deck class="mb-3">
      <b-card
          class="text-center"
          title="What is our Payroll Deduction Scheme"
          img-src="/tiles/thinking.png"
          img-alt="Thinking"
          img-top
      >
        <b-button to="/payroll-deduction-scheme/what-is-it" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          img-src="/tiles/work-benefits.png"
          img-alt="Group of employees high fiving"
          img-top
      >
        <b-card-title>
          Benefits for<br/>Employees
        </b-card-title>
        <b-button to="/payroll-deduction-scheme/benefits-for-employees" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          img-src="/tiles/employer-benefits.png"
          img-alt="Employer Benefits"
          img-top
      >
        <b-card-title>
          Benefits for<br/>Employers
        </b-card-title>
        <b-button to="/payroll-deduction-scheme/benefits-for-employers" variant="outline-success">Find Out More</b-button>
      </b-card>
    </b-card-group>

    <h5 class="font-weight-bold">Join our existing Payroll Partners in offering this Great Benefit To Your Employees!!</h5>
    <p>We currently work with over 40 organisations, large and small from a variety of industry sectors. Some of our Payroll Partners include:</p>

    <b-carousel :interval="3000" class="mb-3 partner-carousel">
      <b-carousel-slide
      v-for="(group, index) in images"
      :key="index"
      >
        <template #img>
          <b-row>
            <b-col
                cols="6"
                md="3"
                v-for="(image, imageIndex) in group"
                :key="`group-${index}-image-${imageIndex}`"
                align-self="center"
                class="text-center"
            >
              <b-img
                  class="my-3 partner-image"
                  fluid
                  :src="`/payroll-partners/${image}.png`"
              />
            </b-col>
          </b-row>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'Payroll Deduction Scheme',
  metaInfo() {
    return {
      title: 'Our Payroll Deduction Scheme | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "payroll, deduction, scheme" },
        { name: 'description', content: "" }
      ],
    };
  },
  data() {
    return {
      images: [
          [
            "action-together",
            "aph",
            "ca",
            "dekko"
          ],
          [
            "ga-et",
            "active-tameside",
            "ef",
            "gm-ic"
          ],
          [
            "hawke",
            "idf",
            "gm-icp",
            "gmpf",
          ],
          [
            "jigsaw",
            "rosehill",
            "saint-anne",
            "tameside"
          ],
          [
            "tameside-gic",
            "tret",
            "vat",
            "whitecroft"
          ]
      ]
    }
  }
}
</script>

<style scoped>
.partner-image {
  max-height:150px;
}

@media (max-width: 767px) {
  .partner-carousel {
    height: 250px;
  }
  .partner-image {
    max-height: 100px;
  }
}

</style>
